.sp_footerTiny {
  visibility: visible !important;
  margin: 0 auto;
  max-width: 600px;
  text-align: center;

  li {
    display: inline-block;

    a {
      text-decoration: none;
    }
  }

  .pl_link100--secondary,
  .pl_link75--secondary {
    color: var(--oc-semantic-color-text-secondary)
  }
}
