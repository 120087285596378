
.sp_footerNormal {
  visibility: visible !important;
  color: var(--oc-semantic-color-text-secondary);

  .sp_color_black100 {
    color: var(--oc-base-color-black-100);
  }

  li {
    display: inline-block;
    color: var(--oc-semantic-color-text-secondary);

    a {
      text-decoration: none;
      color: var(--oc-semantic-color-text-secondary);
    }
  }

  .pl_link100--secondary,
  .pl_link75--secondary {
    color: var(--oc-semantic-color-text-secondary)
  }


  .sp_usps_container {
    display: flex;
    justify-content: space-evenly;

    > div > div {
      display: flex;
      align-items: center;
      height: 100%
    }
  }

  .sp_first_row,
  .sp_second_row,
  .sp_usps_row,
  .sp_fourth_row,
  .sp_imprint_row {
    border-top: 4px solid #f0f0f0;
  }
}

.mo-frame .sp_footerNormal .sp_first_row,
.mo-frame .sp_footerNormal .sp_second_row,
.mo-frame .sp_footerNormal .sp_usps_row,
.mo-frame .sp_footerNormal .sp_fourth_row,
.mo-frame .sp_footerNormal .sp_imprint_row {
  border-top: 0;
}
