.sp_footerSmall {
  visibility: visible !important;

  li {
    display: inline-block;

    a {
      text-decoration: none;
    }
  }

  .sp_usps_container {
    display: flex;
    justify-content: space-evenly;

    > div > div {
      display: flex;
      align-items: center;
      height: 100%
    }
  }

  .sp_first_row,
  .sp_usps_row,
  .sp_imprint_row {
    border-top: 4px solid #f0f0f0;
  }

  .pl_link100--secondary {
    color: var(--oc-semantic-color-text-secondary)
  }
}

.mo-frame .sp_footerSmall .sp_first_row,
.mo-frame .sp_footerSmall .sp_usps_row,
.mo-frame .sp_footerSmall .sp_imprint_row {
  border-top: 0;
}
